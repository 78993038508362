import { useState } from "react";
import "./App.css";
import Header from "./components/Header";
import NotifyModal from "./components/NotifyModal";

function App() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="min-h-screen relative">
      <Header />
      <div className="bg-primary min-h-screen min-h-full grid md:grid-cols-2 relative">
        <div className="bg-hero bg-image md:block hidden bg-center bg-no-repeat bg-cover absolute inset-0"></div>
        <div className="bg-primary pt-[72px] z-[2] relative w-full h-full md:rounded-full flex justify-start sm:items-center xl:items-end sm:mt-0">
          <div className="p-5 h-full w-full md:w-auto flex flex-col xl:pl-[60px] 2xl:pl-[81px]">
            <div className="flex-1  flex flex-col xl:pl-[28px] justify-center">
              <div className="flex items-center max-w-[423px] relative justify-center xl:pb-6 pb-6">
                <span className="bg-white h-[2px] left-0 right-0  absolute "></span>
                <span className="text-white px-2 sm:text-[35px] text-[30px] font-[300] z-[1] bg-primary">
                  Coming Soon
                </span>
              </div>
              <h1 className="text-white xl:leading-[70px] 2xl:leading-[81px] text-[30px] sm:text-[40px] xl:text-[60px] 2xl:text-[71px] font-bold">
                Get Notified{" "}
              </h1>
              <h1 className="text-white  xl:leading-[70px] 2xl:leading-[81px]  text-[30px] sm:text-[40px] xl:text-[60px] xl:text-[60px] 2xl:text-[71px] font-bold mb-2">
                When We Launch
              </h1>
              <div className="mb-[20px] mt-[20px] sm:mt-0 sm:mb-[45px] ">
                <div className="bg-primary border-2 w-full border-white pl-[30px] lg:pl-[56px] py-2 pr-2 rounded-full flex items-center ">
                  <input
                    className="text-white sm:text-[22px] flex-1  border-white font-medium placeholder:text-white bg-primary border-0 outline-none focus:outline-none ring-0 "
                    placeholder="Enter your email address"
                  />
                  <button
                    className="text-black font-medium lg:text-[20px] bg-white rounded-full xl:px-[30px] xl:py-[10px] px-[12px] py-[8px] hidden sm:block text-nowrap "
                    onClick={() => setIsOpen(true)}
                  >
                    Notify Me
                  </button>
                </div>
                <button
                  className="text-black font-medium sm:text-[22px] bg-white rounded-full sm:px-[35px] sm:py-[14px] px-[12px] py-[5px] sm:hidden block my-2 w-full"
                  onClick={() => setIsOpen(true)}
                >
                  Notify Me
                </button>
              </div>
              <p className="text-white md:text-[23px] text-[14px] sm:text-[18px]">
                * Get Discounts & Other Goodies For Joining The Waitlist :)
              </p>
              <div className="border border-white rounded-[12px] max-w-max p-3 mt-[40px] 2xl:mt-[70px]">
                <div className="flex items-center gap-5">
                  <span className="text-white">FOLLOW US</span>
                  <button className="border border-white p-[7px] w-[35px] h-[35px] md:w-[50px] md:h-[50px] rounded-full justify-center items-center flex">
                    <img src="/assets/images/x.png " className="" />
                  </button>
                  <button className="border border-white p-[4px] w-[35px] h-[35px] md:w-[50px] md:h-[50px] rounded-full justify-center items-center flex">
                    <img src="/assets/images/Vector.svg " />
                  </button>
                </div>
              </div>
            </div>
            <div className=" max-w-full mt-2 md:max-w-[350px] lg:max-w-[500px] 2xl:max-w-[641px] border-t border-white">
              <div className="flex justify-evenly sm:justify-start gap-4 text-white pt-5 xl:pl-[28px]">

              <a href="#" className="text-[14px] lg:text-lg">Terms of Service</a>
              <a href="#" className="text-[14px]  lg:text-lg">Privacy Policy</a>
              <a href="#" className="text-[14px]  lg:text-lg">Cookie Policy</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NotifyModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>
  );
}

export default App;
