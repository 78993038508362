import React from 'react'

const Header = () => {
  return (
    <div className="py-5 absolute w-full top-0 flex justify-center bg-white z-10">
        <div>
          <img src="/assets/images/logo.png" className="max-w-full w-[200px]" />
        </div>
      </div>
  )
}

export default Header
